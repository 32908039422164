import { createApp, onMounted } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
.use(store)
.use(router)
.use(ElementPlus)
.mount('#app')

// 注入 Vuex Store
// app.config.globalProperties.$store = store;

// 使用 onMounted 钩子来监听窗口大小变化
// onMounted(() => {
//   window.onresize = function() {
//     app.config.globalProperties.$store.commit('changeScreen');
//   };
// });

// 注册所有图标, 每个图标都是一个组件
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component)
// }
