<template>
  <div class="bottom">
    <el-row :gutter="10">
      <el-col :xs="{span:22, offset:1}" :sm="{span:22, offset:1}" :md="{span:22, offset:1}" :lg="{span:5, offset:1}" :xl="{span:5, offset:1}">
        <div class="bottom-info">
          <el-image style="width:70%; height:60px" :src="brokerLogo" :fit="'fill'" />
          <div>Operated by {{realtorName}}, who is licensed agent of {{brokerName}}</div>
        </div>
      </el-col>
      <el-col :xs="{span:22, offset:1}" :sm="{span:22, offset:1}" :md="{span:22, offset:1}" :lg="{span:17, pull:1}" :xl="{span:17, pull:1}">
        <div class="bottom-claim">
          <a href="https://www.realtor.ca/en" alt="Powered by: REALTOR.ca" target="_blank"> <img width="90" height="40" src="https://www.realtor.ca/images/en-ca/powered_by_realtor.svg"/></a>          
          The trademarks REALTOR<sup>®</sup>, REALTORS<sup>®</sup> and the REALTOR<sup>®</sup> logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA.
          The trademarks MLS<sup>®</sup>, Multiple Listing Service<sup>®</sup> and the associated logos are owned by CREA and identify the quality of services provided by real estate professionals who are members of CREA.
          The trademark DDF<sup>®</sup> is also owned by CREA and identifies the REALTOR.ca Data Distribution Facility (DDF<sup>®</sup>).
          <span class="bottom-claim-agreement" :onclick="showAgreement">Term of Use Agreement</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { ElMessageBox, ElMessage } from 'element-plus'
import { Consts } from '@/utils/consts'
export default {
  name: 'BottomClaim',
  props: {
    realtor: {
      required: true,
      type: String,
    },
    broker: {
      required: true,
      type: String,
    },
    brokerLogo: {
      reuired: true,
      type: String
    }
  },
  data() {
    return {
      realtorName: this.realtor,
      brokerName: this.broker,
      brokerUrl: this.brokerLogo,
    }
  },
  watch: {
    realtor(newVal) {
      this.realtorName = newVal
    },
    broker(newVal) {
      this.brokerName = newVal
    },
    brokerLogo(newVal) {
      this.brokerUrl = newVal
    },
  },
  methods: {
    showAgreement() {
      ElMessageBox.alert(Consts.TermOfUseAgreement, 'Term of Use Agreement', {
        confirmButtonText: 'OK',
        callback: (action) => {
          ElMessage({
            type: 'info',
            message: `action: ${action}`,
          })
        },
      })
    }    
  },  
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.bottom {
  z-index: 1;  
  background-color: $theme-color;
  color: white;
  font-size: 1em;
  text-align: left;
}
.bottom-claim-agreement {
  color: red;
  cursor: pointer;
}

/* 设置了浏览器宽度不小于1201px时的显示 */
@media screen and (min-width: 1201px) {
.bottom-logo {
  padding: 35px 0;
  vertical-align: middle;
}
.bottom-info {
  padding: 30px 0;
  font-size: 1em;
  text-align: center;
}
.bottom-claim {
  padding: 20px 0;
  font-size: 1em;
}
}
@media screen and (max-width: 1200px) {
.bottom-logo {
  padding: 20px 0;
  vertical-align: middle;
}
.bottom-info {
  padding: 20px 20px;
  font-size: 1em;
  text-align: center;
}
.bottom-claim {
  padding: 10px 10px;
  font-size: 0.3em;
}
}
</style>