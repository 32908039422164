<template>
  <top-nav :realtor="realtorName" />
  <router-view v-slot="{ Component }">
    <transition>
      <keep-alive exclude="DetailView">
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>
  <bottom-claim :realtor="realtorName" :broker="brokerName" :brokerLogo="brokerLogoUrl" />
</template>


<script>
// import { Config } from '../public/conf/config.js'
import { remoteJS } from '@/utils/remoteJS.js'
import { getMetroList } from '@/utils/api'
import TopNav from '@/components/TopNav'
import BottomClaim from '@/components/BottomClaim'
export default {
  components: { TopNav, BottomClaim },
  data() {
    return {
      metroName: '',
      brokerLogoUrl: '',
      brokerName: '',
      realtorName: 'John',
    }
  },
  created() {
    // 设置CSS变量的值
    document.documentElement.style.setProperty('--width', window.innerWidth + 'px');
    document.documentElement.style.setProperty('--height', window.innerHeight + 'px');

    let realtor = 'agent';
    if (process.env.NODE_ENV == 'production') {
      const host = window.location.host;
      realtor = host.replace('.homapp.com', '');
    }
    remoteJS(realtor).then(() => {
      const Config = Configs();
      this.metroName = Config.MetroName;
      this.brokerLogoUrl = Config.BrokerLogo;
      this.brokerName = Config.BrokerName;
      this.realtorName = Config.RealtorName.en;

      this.$store.commit('changeMetroName', this.metroName);
      getMetroList(this.metroName).then(res => {
        this.$store.commit('changeMetroCity', res.data);
      }).catch(err => {
        console.log('Not get metro city');
      })
    })
  },
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
:root {
  --width: 0;
  --height: 0;
}
</style>
