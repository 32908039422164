import { request } from '@/utils/request'
import { Consts } from '@/utils/consts'

export function getMetroList(params = {}) {
  let data = `metro_area=${params}`;
  return request({
    method: 'GET',
    api: 'metro_area',
    data: data,
  })
}

export function getMlsList(params = {}) {
  let data = `from=${params.from}&size=${params.size}`;
  let str = JSON.stringify(params.filters);
  if (str != '{}') {
    str = str.toLowerCase();
    data += str.replaceAll(':', '=').replaceAll(',"','&').replaceAll(',', '')
        .replaceAll('"', '').replace('{', '&').replace('}', '')    
        .replace('dwelling', 'type').replace('bedrooms', 'bed_rooms')/* .replace('/condo', '') */
        .replace('price', 'price_min').replace('-$', '&price_max=').replace('$', '')
        .replace('lotsize', 'lot_size_min').replace('-', '&lot_size_max=');
  }

  return request({
    method: 'GET',
    api: 'property_list',
    data: data,
  })
}

export function getFeaturedList(params = {}) {
  let data = `from=${params.from}&size=${params.size}&city=${params.filters.city}`;
  switch (params.filters.breakdown) {
    case 'New Listing':
      data += `&type=new_listing`;
      break;
    case 'Just Sold':
      data += `&type=just_sold`;
      break;
    case 'Price Change':
      data += `&type=price_change`;
      break;
    case 'Open House':
      data += `&type=open_house`;
      break;
  };
  switch (params.filters.sortby) {
    case 'Price(low to high)':
      data += `&sort=price_low`;
      break;
    case 'Price(high to low)':
      data += `&sort=price_high`;
      break;
    default:
      data += `&sort=default`;
  };

  return request({
    method: 'GET',
    api: 'featured_list',
    data: data,
  })
}

export function getSoldList(realtorId, params = {}) {
  let data = `realtor_id=${realtorId}&from=${params.from}&size=${params.size}`;
  if (params.filters.city !== '') {
    data += `&city=${params.filters.city}`;
  }
  if (params.filters.year !== '') {
    data += `&year=${params.filters.year}`;
  }

  return request({
    method: 'GET',
    api: 'sold_list',
    data: data 
  })
}

export function getPropertyDetail(params = {}) {
  switch(params.name) {
    case 'MLS':
    case 'Featured':
    case 'Sold':
      return request({
        method: 'GET',
        api: 'property',
        data: `sysid=${params.sysid}`
        // data: { sysid: params.sysid }
      });
    default:
  }
}
